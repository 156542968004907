/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Blockquote from '../components/blockquote';
import ClientList from '../components/client-list';
import Form from '../components/form';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ButtonGroup from '../components/button-group';
import Button from '../components/button';


const WordPressConsultantPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
        >
            <SEO
                title="WordPress Consultant"
                description="I'm a trusted WordPress Consultant working with start ups, businesses and big brands worldwide. Hire me to make your WordPress project a success today."
                imageAlt="WordPress Consultant"
            />
            <Hero
                imageName="tom-hirst-headshot.png"
                imageAlt="WordPress Consultant, Tom Hirst"
                imageCaption="WordPress Consultant, Tom Hirst"
            >
                <h1>A trusted WordPress Consultant you can rely on.</h1>
                <p><strong>Are you looking for:</strong></p>
                <ul>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        An independent technical audit of your current WordPress solution?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Project planning and strategy from a WordPress consultant with a
                        decade&apos;s worth of experience?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Someone who has advised on, lead, and delivered advanced WordPress
                        solutions for start ups, medium-large businesses, big brands
                        and WordPress VIP agencies worldwide?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        A reliable and trusted expert point of contact for all of
                        your WordPress questions, queries and needs?
                    </li>
                </ul>
                <p>
                    <strong>You&apos;re in the right place.</strong> Here&apos;s what
                    you can do next:
                </p>
                <ButtonGroup>
                    <Button to="#hire-me" icon="arrow-right" text="Hire me today" color="green" anchor />
                    <Button to="#keep-reading" icon="arrow-down" text="Keep reading" anchor />
                </ButtonGroup>
            </Hero>
            <Section narrow>
                <h2 id="keep-reading">How can a WordPress Consultant help your business?</h2>
                <p>I can help you make your WordPress project successful in these situations:</p>
                <ol>
                    <li>You&apos;re using WordPress for your website/app already, but feel like your
                        solution could be better.
                    </li>
                    <li>
                        You&apos;ve heard great things about WordPress, but need to
                        know if it&apos;s the right fit for your project specifically.
                    </li>
                    <li>
                        You&apos;re starting an advanced WordPress project and
                        require assistance with planning the initial approach and
                        ongoing strategy.
                    </li>
                    <li>
                        You&apos;re set on WordPress, but want insight into different
                        implementation methods. For instance, should you use Advanced
                        Custom Fields, Gutenberg or WordPress as a headless content
                        management system?
                    </li>
                    <li>
                        You&apos;re looking to launch an eCommerce project on
                        WordPress using WooCommerce and need guidance.
                    </li>
                    <li>
                        You want to make use of the WordPress REST API to power
                        a detached front end using a JavaScript framework like
                        React or a static site generator like Gatsby.
                    </li>
                    <li>You need regular technical WordPress consultancy and advice from an expert
                        in the field.
                    </li>
                </ol>
                <p>Do any of these scenarios fit the bill? You can <a href="#hire-me">hire me</a> to help today.</p>
                <h2>Why hire Tom Hirst as your WordPress Consultant?</h2>
                <p>
                    Whether you have a WordPress project already or you&apos;re
                    thinking about starting one, you&apos;ll be aware of the
                    platforms popularity.
                </p>
                <p>
                    Features like themes and plugins have lead to WordPress being
                    the go-to content management system for { <a href="https://w3techs.com/" target="_blank" rel="noopener noreferrer">over 34%</a> } of
                    all websites on the internet.
                </p>
                <p>
                    While this an astounding feat, there are thousands of WordPress
                    projects done badly.
                </p>
                <p>
                    Poor consideration of performance, accessibility, code structure,
                    version control, deployment strategy, security and SEO can be damaging
                    to your WordPress solution.
                </p>
                <p>
                    Having being a part of the WordPress ecosystem for over 10 years,
                    I&apos;ve seen it all.
                </p>
                <p>
                    During this time, I&apos;ve helped start ups, medium, large
                    and global businesses, big brands and WordPress VIP agencies
                    deliver successful projects. Often at enterprise scale.
                </p>
                <p>
                    I&apos;ve built up a large contact base with trusted partners in design,
                    development, SEO and digital marketing.
                </p>
                <p>
                    I can oversee your project through discovery, development, go live and
                    iterative improvement.
                </p>
                <p>
                    I want to be your WordPress partner for the long term by providing consistent
                    value to your business.
                </p>
                <p>My work is guaranteed. If at any point during the first week of our engagement
                    you&apos;re dissatisfied, I&apos;ll refund your investment.
                </p>
                <p>I&apos;ll help your business get its WordPress project done right.</p>
                <h3>New WordPress projects</h3>
                <p>A new WordPress development project is a big undertaking for your business.</p>
                <p>Let me help you make the most of your investment by working with you to plan
                    the right approach as your WordPress consultant.
                </p>
                <h3>Existing WordPress projects</h3>
                <p>If your WordPress solution is not cutting it, your business could be
                    missing out on revenue.
                </p>
                <p>Let me begin to solve this by performing an audit of your current setup
                    and delivering a document of actionable improvements.
                </p>
                <h2 id="hire-me">Ready to make your WordPress project a success?</h2>
                <p>
                    I&apos;d love to work with you on this. Fill out the form below
                    to let me know more:
                </p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, we need your help to make sure our WordPress project is set up for success."
                    budgetLabel="Engagements from £2500"
                    messageLabel="Tell me where your WordPress project could be better"
                    pathname={ location && location.pathname }
                />
                <h3>I&apos;m well trusted</h3>
                <p>I&apos;ve worked on WordPress projects for:</p>
                <ClientList />
                <h3>Kind words from people who have hired me</h3>
                <div className="blockquote-list-with-middle">
                    <Blockquote
                        quote="Tom has consistently brought unmatched talent, focus, and guidance on several custom WordPress projects for our clients, often going beyond the brief to solve the real problem."
                        cite="Brian Frolo"
                        imageName="bf-headshot.jpg"
                        imageAlt="Brian Frolo"
                    />
                    <Blockquote
                        quote="Tom is extremely knowledgeable on all technical aspects of web development. We are very happy with Tom's work and would not hesitate to recommend."
                        cite="Christian Garnett"
                        imageName="cg-headshot.jpg"
                        imageAlt="Christian Garnett"
                    />
                    <Blockquote
                        quote="I've been working with Tom over the past couple of years on our global marketing websites, built on top of the WordPress CMS. Tom is highly proficient in WordPress development and has become an invaluable contractor for us."
                        cite="Nick Wale"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Working with Tom has been a great experience. I would recommend Tom and will continue to
                work with him on future projects."
                        cite="Karen Gee"
                        imageName="kg-headshot.jpg"
                        imageAlt="Karen Gee"
                    />
                    <Blockquote
                        quote="Tom took a complex, continually shifting brief and still produced a high quality website for us. At all stages he was amiable, responsive, and more than willing to help us with further iterations."
                        cite="Chay Hunter"
                        imageName="ch-headshot.jpg"
                        imageAlt="Chay Hunter"
                    />
                </div>
                <h2>There&apos;s no time like the present</h2>
                <p>
                    Head back up to the form and tell me more about your WordPress project.
                    We can get started today.
                </p>
                <a className="button is-green has-icon" href="#hire-me">
                    Take me back to the form!
                    <FontAwesomeIcon icon="arrow-up" />
                </a>
            </Section>
        </Layout>
    );
};

WordPressConsultantPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

WordPressConsultantPage.defaultProps = {
    location: {},
};

export default WordPressConsultantPage;
